<template>
    <div class="recipes" >
        <appPopUp :popUp="show" :btnPrev="btnPrev" :btnNext="btnNext" :text="text" :title="title" :img="img" :id="id" :getAllId="getAllId" @closePopUp="closePopUp" @contentId="contentId" />
        <h2 class="titleBlock" v-html="pageTitle"></h2>
        <template>
            <swiper class="swiper" :options="swiperOption">
                <swiper-slide v-for="recipe in recipes" :key="recipe.id.number">
                    <div class="recipes-box" @click="showPopUp(recipe.id)">
                        <div class="recipes-box__img">
                            <img :src="recipe.preview_image">
                        </div> 
                        <div class="recipes-box__content">
                          <div class="recipes-box__title" v-html="recipe.title"></div>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
        </template>
        <div class="slider-button-line">
            <div class="slider-button-line__box">
                <button class="slider-button" id="slider-button-prev" slot="button-prev"><i class="far fa-angle-left"></i></button>
                <button class="slider-button" id="slider-button-next" slot="button-next"><i class="far fa-angle-right"></i></button>
            </div>
            <div class="slide-scrollBar">
                <div class="slide-drag"></div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    import VueAxios from 'vue-axios'
    import PopUp from '@/components/PopUp'
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
    import 'swiper/css/swiper.css'
    import { HTTP, Routes } from '../API'
    export default {
        name: 'swiper-example-free-mode',
        title: 'Free mode / No fixed positions',
        components: {
            Swiper,
            SwiperSlide,
            appPopUp: PopUp,
        },
        props:{
            langContent:{type: String, default: 'ru'},
        },
        data() {
            return {
                blockScroll: false,
                removeScrollButton: true,
                show: false,
                text:'',
                title:'',
                img:'',
                id: 0,
                swiperOption: {
                    loop: false,
                    slidesPerView: 2,
                    slidesPerColumn: 1,
                    spaceBetween: 30,
                    watchOverflow: true,
                    scrollbar: {
                        el: '.slide-scrollBar',
                        draggable: true,
                        dragSize: 'auto',
                        dragClass: 'slide-drag',
                        snapOnRelease: true
                    },
                    navigation: {
                        nextEl: '#slider-button-next',
                        prevEl: '#slider-button-prev'
                    }
                },

                pageTitle: null,
                allId:[],
                getAllId: null,
                recipes: [],
                nextButton: null,
                btnPrev: 'Предыдущая',
                btnNext: 'Следующая'
            }
        },
        methods:{
            closePopUp(popUp){
                this.show = !popUp
                this.$router.push({ path: `/${this.langContent}/recipes` })
                this.blockScroll = true
                this.$emit('noScroll', this.blockScroll)
            },
            contentId(idContent){
                let index = idContent
                // Всего контента
                let allСontent = this.recipes.length
                if(allСontent !== index){
                    this.showPopUp(index)
                }
            },
            showPopUp(index){
                let number = this.recipes.findIndex( item =>{
                    return item.id == index
                })
                let obj = new Object();
                this.recipes.forEach(element => {
                    let id =  element.id
                    obj.id = id
                    this.allId.push(Object.assign({}, obj))
                });
                this.getAllId  = this.allId
                this.allId = []         
                this.show = true
                this.text = this.recipes[number].detail_text
                this.title = this.recipes[number].title
                this.img = this.recipes[number].detail_image
                this.id = Number(this.recipes[number].id)
                let code = this.recipes[number].code
                // Заблокировать скролл
                this.$emit('noScroll', this.blockScroll)
                this.writeIdArticles(code)
            },
            // Записать id статьи
            writeIdArticles(code){
                this.$router.push({ path: `/${this.langContent}/recipes/${ code }` })
            },
            getContent(){
                HTTP.get(Routes.recipes)
                .then(  response => {
                    // Рецепты
                    this.recipes = response.data.recipes.items
                    this.btnNext = response.data.recipes.btn_elem_next
                    this.btnPrev = response.data.recipes.btn_elem_previos
                    // Заголовок
                    this.pageTitle = response.data.recipes.title
                    this.nextButton = response.data.recipes.btn_next
                    setTimeout(() =>{
                        this.$emit('buttonNextScroll', this.nextButton)
                    },0)

                })
                .catch( error => {
                    console.log(error);
                });
            }
        },
        mounted() {
            this.getContent();
            // axios.get(`https://macchoco.ru/includes/api/_getData.php?type_data=recipes&lang=${this.langContent}`)
            //     .then(  response => {
            //         // Рецепты
            //         this.recipes = response.data.recipes.items
            //         // Заголовок
            //         this.pageTitle = response.data.recipes.title
            //         this.nextButton = response.data.recipes.btn_next
            //         setTimeout(() =>{
            //             this.$emit('buttonNextScroll', this.nextButton)
            //         },0)

            // })
            // .catch( error => {
            //     console.log(error);
            // });
            
            // Заголовок
            let title = document.getElementsByClassName('recipes-box__title')
            setTimeout(()=>{
                for (let i = 0; i < title.length; i++) {
                    title[i].classList.add('animation-text-active')
                }
            },650)

            // Показать статью по прямой ссылке
            setTimeout(()=>{
                 this.recipes.forEach(el => {
                    if(el.code === String(this.$route.params.id) ){
                        let index = el.id
                        this.showPopUp(index)
                        return false
                    }
                });
            },200)
        },
        beforeCreate(){
            this.removeScrollButton = true
            this.$emit('removeButtonScrollFooter',this.removeScrollButton)
        }
    }
</script>
